<template>
  <v-container v-if="gebruiker">
    <v-data-table :search="search" :headers="headers" :custom-filter="filterGrid" :items="instanties"
      hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Gemeente of instantie</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Zoek in grid" class="mx-4"></v-text-field>
          <v-spacer></v-spacer>

          <v-btn icon @click="openDialogNew" v-if="gebruiker.has_admin">
            <v-icon medium>mdi-plus-circle</v-icon>
          </v-btn>


          <v-dialog v-model="dialog" max-width="500px">

            <v-card>
              <v-card-title>
                <span class="headline">{{ itemModel.naam }}</span>
              </v-card-title>

              <v-card-text>
                <v-form @submit.prevent="" v-model="valid">
                  <v-text-field v-model="itemModel.naam" :rules="[rules.required]" label="Naam gemeente of instantie"
                    required autofocus></v-text-field>

                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Annuleren</v-btn>
                <v-btn color="blue darken-1" :disabled="!valid" @click="updateInstantie">Opslaan</v-btn>
              </v-card-actions>
              <v-container>

                <v-alert outlined type="error" v-for="(err, index) in errs" :key="index">
                  {{ err.msg }}</v-alert>
              </v-container>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.opties`]="{ item }">
        <v-icon v-if="gebruiker.has_admin" text @click="openDialogUpdate(item)" medium>mdi-cog</v-icon>
      </template>
      <template v-slot:[`item.naam`]="{ item }">
        <div class="width:100%" @click="navigateRow(item)">
          {{ item.naam }}
        </div>
      </template>
      <template v-slot:[`item.aanpassen`]="{ item }">
        <div @click="navigateRow(item)">
          <v-icon medium>mdi-chevron-double-right</v-icon>
        </div>
      </template>
    
    </v-data-table>
  </v-container>
</template>

<script>

import client from "api-client";
import { mapState } from "vuex";

export default {
  name: "AdminInstanties",
  data: () => ({
    search: "",
    headers: [
      { value: "opties", sortable: false, width: '50px' },
      { text: "Naam", value: "naam", sortable: true },
      { text: "", value: "aanpassen", align: 'end' },
    ],
    dialog: false,
    instanties: [],
    valid: false,
    rules: {
      required: (value) => !!value || "Verplicht.",
    },
    itemModel: {},
    errs: []
  }),
  methods: {
    filterGrid(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    navigateRow(row) {
      this.$router.push({ name: 'Audits', params: { instantieId: row.id } })
    },
    getInstanties() {
      client.fetchInstanties().then((data) => {
        this.instanties = data;
      });
    },
    openDialogNew() {
      this.errs = []
      this.itemModel = {}
      this.dialog = true
    },
    openDialogUpdate(item) {
      this.errs = []
      this.itemModel = item
      this.dialog = true
    },
    updateInstantie() {
      if (this.itemModel.id) {
        client.updateInstantie(this.itemModel.id, {
          naam: this.itemModel.naam
        }).then(() => {
          this.getInstanties()
          this.dialog = false
        }).catch((err) => {
          this.errs = err
        })
      } else {
        client.addInstantie({
          naam: this.itemModel.naam
        }).then(() => {
          this.getInstanties()
          this.dialog = false
        }).catch((err) => {
          this.errs = err
        })
      }
    },

  },
  computed: {
    ...mapState({
      gebruiker: "gebruiker",
    }),
    instantie: function () {
      return this.$store.getters.getInstantie(this.$route.params.instantieId)
    },
    hasAudit: function () {
      return (this.instantie?.hasAudit || this.gebruiker.has_admin)
    },
    hasUserAdmin: function () {
      return (this.instantie?.hasUserAdmin || this.gebruiker.has_admin)
    }
  },
  created() {
    this.getInstanties()
  }
};
</script>